const apiUrl = "https://api.blanksblank.com";

async function getBlank({ useAI }) {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ useAI: useAI }),
  };

  const response = await fetch(apiUrl + "/blank/random", requestOptions);
  const data = await response.json();
  return data;
}

export default getBlank;
