import React from "react";
import { Grid, Button, Switch, Spacer, Text } from "@nextui-org/react";

import "./App.css";
import logo from "./open-ai-gradient.svg";
import BlankService from "./services/blanks-blank";

function App() {
  const [useAI, setUseAI] = React.useState(false);
  const [quote, setQuote] = React.useState(null);

  const handleToggle = () => {
    setUseAI(!useAI);
  };

  const getQuote = async () => {
    try {
      const response = await BlankService({ useAI });
      setQuote(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Spacer y={1} />
        <Grid.Container gap={1} justify="center">
          <Grid>
            <Switch
              size={"lg"}
              color="primary"
              checked={useAI}
              onChange={handleToggle}
            />
          </Grid>
          <Grid>
            <Text
              size={20}
              css={{
                textGradient: useAI
                  ? "45deg, $yellow600 -20%, $red600 100%"
                  : "45deg, $blue600 -20%, $pink600 50%",
              }}
              weight="bold"
            >
              OpenAI GPT-3
            </Text>
          </Grid>
        </Grid.Container>
        <Button color="gradient" auto ghost onPress={getQuote}>
          <Text weight="bold">get quote</Text>
        </Button>
        <Spacer y={1} />
        {quote ? (
          quote.error ? (
            <Text color="error" weight="bold">
              "try again"
            </Text>
          ) : (
            <Text weight="bold" size={25}>
              {`it's colder than a ${quote.creature}'s ${quote.thing}`}
            </Text>
          )
        ) : (
          ""
        )}
      </header>
    </div>
  );
}

export default App;
